<div class="radio-inputs">
  <label class="radio">
    <input 
      type="radio" 
      [name]="'radio-' + instanceId" 
      value="mobile" 
      [(ngModel)]="device" 
      (change)="setDeviceUrl($event)">
    <span class="name">Mobile</span>
  </label>
  <label class="radio">
    <input 
      type="radio" 
      [name]="'radio-' + instanceId" 
      value="desktop" 
      [(ngModel)]="device" 
      (change)="setDeviceUrl($event)">
    <span class="name">Desktop</span>
  </label>
</div>
