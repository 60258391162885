import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { WebsiteAnalyze } from 'src/app/shared/models/user.models';

@Component({
  selector: 'app-device-button',
  templateUrl: './device-button.component.html',
  styleUrls: ['./device-button.component.scss'],
})
export class DeviceButtonComponent implements OnInit {
  @Output() public deviceEvent = new EventEmitter<string>()
  @Input() instanceId: string;
  deviceDebut : string = "mobile";
  website: WebsiteAnalyze[];
  userId: string;
  isDisabled : boolean
  device: string ;



  constructor() { }


  ngOnInit(): void {
    this.device = 'mobile';
    this.userId = localStorage.getItem(ConstantService.localStorageKeys.userId);
    if(localStorage.getItem(ConstantService.loadingUrl.device) === undefined) {
      localStorage.setItem(ConstantService.loadingUrl.device, this.deviceDebut);
    }
    const device = localStorage.getItem(ConstantService.loadingUrl.device);
    this.device = device === 'tablet' ? 'mobile': device
  }
  setDeviceUrl(event: Event) {
    const target = event.target as HTMLInputElement;
    const deviceValue = target.value;
  
    this.deviceEvent.emit(deviceValue);
    this.device = deviceValue;
    localStorage.setItem(ConstantService.loadingUrl.device, deviceValue);
  }
  

}
